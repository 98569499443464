//Set Default font
body {
	font-family: $base-font;
	color: $color-font-default;
	font-weight:300;
}

h1,.h1{
	font-family: $heading-font;
	color: $color-font-default;
	background-color: transparentize($color-white, 0.1);
	font-size: 40px;
	font-weight: 200;
	display: inline-block;
	border-left:5px solid $color-garnet;
	padding: 15px 10px;
	margin: 20px 0;
}

h2{
	font-family: $heading-font;
	text-transform: capitalize;
	font-size: 40px;
	color: $color-font-default;
	margin: 40px 0 30px;
	border-left: 5px solid $color-garnet;
	padding: 5px 10px;
}

h3{
	font-family: $heading-font;
	font-size: 30px;
	//text-transform: capitalize;
	color: $color-font-default;
	font-weight: 400;
	margin: 25px 0;
}

h4{
	font-size: 18px;
	font-weight: 600;
	color: $color-font-default;
	margin: 25px 0 15px 0 ;
}

p{
	font-size: 18px;
	font-weight: 300;
	line-height: 1.7;
}
