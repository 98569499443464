/*
 Theme Name:   Mac-Flats
 Author:       Lyles Sutherland
 Author URI:   http://www.lyles-sutherland.co.uk
 Template:     smeprofessional-pm
 Version:   0.0.1
*/

@import "utils/variables";
@import "utils/mixins";
@import "utils/functions";
@import "utils/placeholders";

@import "base/base";
@import "base/reset";
@import "base/typography";
@import "base/forms";


@import "layout/header";
@import "layout/navigations";
@import "layout/footer";
@import "layout/sidebars";
@import "layout/masthead";

@import "vendor/featured-properties";
@import "vendor/properties-overview";
@import "vendor/property";
@import "vendor/searchers";
@import "vendor/slick";

@import "pages/home";
@import "pages/properties";
@import "pages/blog";
@import "pages/about-us";
@import "pages/contact-us";
