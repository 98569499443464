@mixin bp-x-large {
	@media only screen and (max-width: 1500px) {
		@content;
	}
}
@mixin bp-large {
	@media only screen and (max-width: 1200px) {
		@content;
	}
}
@mixin bp-medium {
	@media only screen and (max-width: 992px) {
		@content;
	}
}
@mixin bp-small {
	@media only screen and (max-width: 767px) {
		@content;
	}
}
@mixin bp-x-small {
	@media only screen and (max-width:  480px) {
		@content;
	}
}
@mixin bp-xx-small {
	@media only screen and (max-width:  320px) {
		@content;
	}
}
