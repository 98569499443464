.screen-reader-text {
	display: none;
	visibility: hidden;
}

.no-gutter {
	> [class*='col-'] {
		padding-right: 0;
		padding-left: 0;
	}

	margin-left: 0;
	margin-right: 0;

}

img {
	max-width: 100%;
}

.colored {
	color: $color-garnet;
}

.container-gray-background {
	background-color: $color-container-background;
	padding: $padding-30-0;
}

.margin-top-50 {
	margin: 50px 0 0 0;
}

.padding-0 {
	padding: 0;
}

.padding-30-0 {
	padding: 30px 0;
}

.padding-bottom-50 {
	padding-bottom: 50px;
}

.margin-50-0 {
	margin: 50px 0;
}

.container-garnet-background {
	background-color: $color-garnet;
}

.button-wrapper {
	margin: 50px 0;
}

.lowercase {
	text-transform: lowercase;
}

.btn {
	&:hover {
		text-decoration: none;
	}

	padding: 10px 25px;
	font-size: 18px;
	border-radius: 5px;
	display: inline-block;
}

.primary-button {
	&:hover, &:active, &:focus {
		background-color: $color-dark-gray;
		color: $color-white;
	}

	background-color: $color-garnet;
	color: $color-white;
}

.secondary-button {
	&:hover, &:active, &:focus {
		background-color: $color-garnet;
		color: $color-white;
	}

	background-color: $color-dark-gray;
	color: $color-white;
}

.equal-list {
	li {
		width: 25%;

		@include bp-x-small {
			width: 50%;
		}

	}
}

.well,
section {
	ul {
		li {
			&:before {
				content: '\f00c';
				color: $color-garnet;
				font-family: FontAwesome;
				margin-left: -22px;
				margin-right: 5px;
			}

			margin: 0 0 5px 17px;
			padding-left: 5px;
		}

		list-style: none;
		padding: 0;

	}
}

.cli-plugin-main-link {
	display: none;
}

.hidden-xxs {
	@include bp-x-small {
		display: none;
	}
}

.accordion-button {
	&:after{
		@include bp-small{
			position: absolute;
			right: 10px;
			top: 25%;
		}
	}

	@include bp_small {
		position: relative;
		padding-right: 30px;
	}
}

.container-image-background {
	@include bp-small {
		padding-bottom: 0;
	}
}


.float-image{
	float: left;
	margin: 15px 15px 20px 0;
	border: 2px solid #77223B;
	padding: 10px;
}
