.wpp_search_elements {
	.wpp_search_group{
		label,
		.wpp_dash{
			display: none;
		}

		.wpp_search_select_field,
		.wpp_search_input_field_price {
			font-size: 16px;
			margin-bottom: 15px;
		}


		list-style: none;
		padding: 0;
	}

	.wpp_search_button{
		@extend .btn;
		@extend .secondary-button;
	}

	list-style: none;
	padding: 0;
}



