option[disabled] {
	display: none;
}

.contact7-field {
	margin-bottom: 10px;
	font-size: 16px;
}

#contact7-message {
	height: 78px;
}

.home {
	.wpcf7-submit {
		float: right;
	}
}

.your-acceptance {
	.wpcf7-list-item {
		margin-left: 0;
	}

	#c7-acceptance {
		width: auto;
		padding: 0;
		margin: 0;
		height: auto;
	}

	margin-bottom: 15px;
}

.container-image-background {
	.your-acceptance {
		color: $color-garnet !important;

		p, a, small {
			color: $color-garnet !important;
		}

		a {
			text-decoration: underline;
		}
	}

	p, a, small {
		color: $color-garnet !important;
	}
}

.form-group {
	input[type="radio"] {
		position: relative;
		width: auto;
		display: inline-block;
		margin-left: 0;
	}

	label{
		color: $color-font-default;
	}
}

.help-block.with-errors {
	color: $color-garnet;
}