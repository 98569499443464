.navbar-default {
	.navbar-nav {
		> li {
			&.current-menu-item{
				> a {
					background-color: $color-garnet;
					color: $color-white;
				}
			}

			> a {
				&:hover, .current-menu-item{
					background-color: $color-garnet;
					color: $color-white;
				}

				padding: 42px 0;

				@include bp-small{
					padding: 10px 5px;
				}
			}

			position: relative;
			display: block;
			width: 14.28%;
			text-align: center;

			@include bp-small{
				width: 100%;
				text-align: left;
			}


		}

		width: 100%;
	}

	border: none;
	margin-bottom: 0;
}

.navbar-toggle{
	float:none;
}
