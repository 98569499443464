#top-header{
	.fa {
		color: $color-garnet;
	}

	.contact-details-wrapper{
		.fa{
			margin-right: 10px;;
		}
	}

	.list-inline {
		li{
			&:not(:last-child){
				border-right:1px solid $color-border-gray;
				padding:0 20px;
			}

			&:first-child{
				padding:0 20px 0 0;
			}

			&:last-child{
				padding: 0 0 0 20px;
			}
		}
	}

	background-color: $color-top-header-background;
	padding: 10px 0;
	border-bottom: 1px solid $color-border-gray;
	margin-bottom: 20px;
}

#middle-header {
	margin-bottom: 20px;
}

#bottom-header {
	margin-bottom: 15px;
}
