//Font Variables
@font-face {
	font-family: 'dessaumedium';
	src: url('/wp-content/themes/mac-flats/fonts/ufonts.com_dessau-medium-regular-webfont.eot');
	src: url('/wp-content/themes/mac-flats/fonts/ufonts.com_dessau-medium-regular-webfont.eot?#iefix') format('embedded-opentype'),
	url('/wp-content/themes/mac-flats/fonts/ufonts.com_dessau-medium-regular-webfont.woff') format('woff'),
	url('/wp-content/themes/mac-flats/fonts/ufonts.com_dessau-medium-regular-webfont.ttf') format('truetype'),
	url('/wp-content/themes/mac-flats/fonts/ufonts.com_dessau-medium-regular-webfont.svg#dessaumedium') format('svg');
	font-weight: normal;
	font-style: normal;
}

$base-font: 'proxima-nova', Helvetica, "Helvetica Neue", Arial, sans-serif;
$heading-font: 'dessaumedium', 'Gill Sans', 'Gill Sans MT', Calibri, sans-serif;

//Margins and Paddings
$padding-15-0: 15px 0;
$padding-30-0: 30px 0;


$margin-50-0: 50px 0;


//Font Colours
$color-font-default: #636363;
$color-font-copyright-footer: #959595;

//Website Colours
$color-garnet: #77223b;
$color-dark-gray: #636363;
$color-white: #FFFFFF;
$color-black: #000000;

$color-top-header-background: #F6F6F6;

$color-website-background: #FDFDFD;
$color-container-background: #F3F3F3;

$color-border-gray: #AEAEAE;

//Social Colours
$color-facebook:#3B5998;
$color-twitter:#00ACED;
$color-googleplus:#DD4B39;
$color-linkedin:#007BB6;
